import React, { useState, useEffect } from "react";
import {  makeStyles } from '@material-ui/core/styles';
import '../styles/sib-styles.css';

const useStyles = makeStyles((theme) => ({
  formBlock: {
    padding: 0,
  },
  formLabel: {
    fontFamily: "'Inter', sans-serif",
    maxWidth: '380px !important',
    color: '#131f37',
  },
  formLabelRow: {
    flexDirection: 'row !important',

    [theme.breakpoints.down(500)]: {
      flexDirection: 'column !important',
    }
  },
  formFieldWrap: {
    background: 'transparent !important',
    border: '0 !important',
    borderRadius: '0 !important',
    display: 'block !important',
    margin: '0 !important',
    width: '100%',
  },
  displayNone: {
    display: 'none',
  },
  formField: {
    fontFamily: "'Inter', sans-serif !important",
    paddingTop: '0.5rem !important',
    paddingBottom: '0.5rem !important',
    paddingLeft: '0.5rem !important',
    paddingRight: '0.5rem !important',
    borderBottomWidth: '1px !important',
    borderStyle: 'solid !important',
    fontSize: '1.125rem !important',
    backgroundColor: 'transparent !important',
    width: '100% !important',
    border: '0 solid #b5b7bd !important',
    maxWidth: '500px !important',
    outline: 'none !important',
    height: 'auto!important',
    fontSize: 'inherit !important',
    boxShadow: 'none !important',
    boxSizing: 'border-box !important',
  },
  formContainer: {
    background: 'transparent !important',
    padding: '0 !important',
    display: 'block !important',
    marginTop: '2.5rem !important',
  },
  formRow: {
    padding: 0,
    marginBottom: '1.5rem',
  },
  formSubmitButton: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    borderWidth: '2px',
    borderColor: '#485776',
    cursor: 'pointer',
    fontWeight: 300,
    position: 'relative',
    backgroundColor: '#131f37',
    color: '#fff',
    display: 'inline-block',
    fontSize: '1rem',
    lineHeight: 1.25,
    textTransform: 'uppercase',
    borderRadius: '0.5rem',
    alignSelf: 'flex-end',
    transition: 'background 500ms linear',
    background: 'linear-gradient(120deg, #081831 50%, #131f37)',
    backgroundSize: '200% 100%',
    backgroundPosition: '0% 0%',
    outline: 'none',
    border: 0,
    margin: 'auto',
    width: '60%',
    maxWidth: '300px',
    
    '&:hover': {
      background: '#0FC1B7 !important',
      color: '#131f37'
    }
  },
  entrySpecification: {
    display: 'none !important'
  } 
}));

function runAwfulSendInBlueCode() {
  if (typeof window === 'undefined')
    return;

  window.REQUIRED_CODE_ERROR_MESSAGE = 'Please choose a country code';
  window.LOCALE = 'en';
  window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";
  window.REQUIRED_ERROR_MESSAGE = "This field cannot be left blank. ";
  window.GENERIC_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";
  window.translation = {
      common: {
      selectedList: '{quantity} list selected',
      selectedLists: '{quantity} lists selected'
      }
  };
  window.AUTOHIDE = Boolean(0);
}

const useCaptchaEvent = param => {
  useEffect(() => {
    const scriptEl = document.createElement('script');
    scriptEl.innerHTML = "function handleCaptchaResponse() { var event = new Event('captchaChange'); document.getElementById('sib-captcha').dispatchEvent(event);}";
    
    document.body.appendChild(scriptEl);
  
    return () => {
      document.body.removeChild(scriptEl);
    }
  }, [ param ]);
}

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

const CompanySignupForm = () => {
    const classes = useStyles();
    const [ companyType, setCompanyType ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ emailAddressCheck, setEmailAddressCheck ] = useState('')
    const [ locale, setLocale ] = useState('en')

    useEffect(runAwfulSendInBlueCode, []);
    useCaptchaEvent(true);
    useScript('https://sibforms.com/forms/end-form/build/main.js');
    useScript('https://www.google.com/recaptcha/api.js?hl=en');
    
    return (
        <div>
            <div className={`sib-form ${classes.formBlock}`}>
  <div id="sib-form-container" className={`sib-form-container`}>
    <div id="error-message" className="sib-form-message-panel">
      <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
        <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
          <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"
          />
        </svg>
        <span className="sib-form-message-panel__inner-text">
            Your registration could not be saved. Please try again.
         </span>
      </div>
    </div>
    <div></div>
    <div id="success-message" className="sib-form-message-panel">
      <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
        <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
          <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
        </svg>
        <span className="sib-form-message-panel__inner-text">
            Thank you for registering. Please click the confirmation email sent to the address below to confirm.
        </span>
      </div>
    </div>
    <div></div>
    <div id="sib-container" className={`sib-container--large sib-container--vertical ${classes.formContainer}`}>
      <form id="sib-form" method="POST" action="https://9b58bf03.sibforms.com/serve/MUIEALMX9m3XK7lCbg3nbbeCnbpi1Kzzz3xT8T5ESdXzd8iV8nzt4EKzSimKOlfWdqBVVHB52NrzM9uvJZIqaBpKeMVjYRGsBKLIlHkLxPZcqPsCYN63GvMcpjLK8-wALK63AGFy-cPHPjqKpzszWUPmw85hxEWmbj67a5LN8_75CEW5v61lPx6z4X0-bmL0UTykNzePdwSzfwTT" data-type="subscription">
        <div>
          <div className={`sib-input sib-form-block ${classes.formRow}`}>
            <div className="form__entry entry_block">
              <div className={`form__label-row ${classes.formLabelRow}`}>
                <label className={`entry__label ${classes.formLabel}`} htmlFor="EMAIL" data-required="*">
                  Business email address
                </label>

                <div className={`entry__field ${classes.formFieldWrap}`}>
                  <input className={`input ${classes.formField}`} onChange={e => setEmail(e.target.value)} value={email} type="text" id="EMAIL" name="EMAIL" autoComplete="off" placeholder="Email" data-required="true" required />
                </div>
              </div>

              <label className="entry__error entry__error--primary" htmlFor="">
              </label>
              <label className={`entry__specification ${classes.entrySpecification}`} htmlFor="">
                Provide your email address to subscribe. For e.g abc@xyz.com
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className={`sib-input sib-form-block ${classes.formRow}`}>
            <div className="form__entry entry_block">
              <div className={`form__label-row ${classes.formLabelRow}`}>
                <label className={`entry__label ${classes.formLabel}`} htmlFor="FIRSTNAME" data-required="*">
                  First name
                </label>

                <div className={`entry__field ${classes.formFieldWrap}`}>
                  <input className={`input ${classes.formField}`} onChange={e => setFirstName(e.target.value)} value={firstName} maxLength="200" type="text" id="FIRSTNAME" name="FIRSTNAME" autoComplete="off" placeholder="First Name" data-required="true" required />
                </div>
              </div>

              <label className="entry__error entry__error--primary" htmlFor="">
              </label>
              <label className={`entry__specification ${classes.entrySpecification}`} htmlFor="">
                Customize this optional help text before publishing your form.
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className={`sib-input sib-form-block ${classes.formRow}`}>
            <div className="form__entry entry_block">
              <div className={`form__label-row ${classes.formLabelRow}`}>
                <label className={`entry__label ${classes.formLabel}`} htmlFor="LASTNAME" data-required="*">
                  Last name
                </label>

                <div className={`entry__field ${classes.formFieldWrap}`}>
                  <input className={`input ${classes.formField}`} onChange={e => setLastName(e.target.value)} value={lastName} maxLength="200" type="text" id="LASTNAME" name="LASTNAME" autoComplete="off" placeholder="Last Name" data-required="true" required />
                </div>
              </div>

              <label className="entry__error entry__error--primary" htmlFor="">
              </label>
              <label className={`entry__specification ${classes.entrySpecification}`} htmlFor="">
                Please enter all details correctly.
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className={`sib-select sib-form-block ${classes.formRow}`} data-required="true">
            <div className="form__entry entry_block">
              <div className={`form__label-row ${classes.formLabelRow}`}>
                <label className={`entry__label ${classes.formLabel}`} htmlFor="COMPANY_TYPE" data-required="*">
                  Which company type describes you best?
                </label>
                <div className={`entry__field ${classes.formFieldWrap}`}>
                  <select value={companyType} className={`input ${classes.formField}`} id="COMPANY_TYPE" name="COMPANY_TYPE" data-required="true" onChange={e => setCompanyType(e.target.value)}>
                    <option value="" disabled hidden>Select one</option>
                    <option className="sib-menu__item" value="11">
                      Brand
                    </option>
                    <option className="sib-menu__item" value="1">
                      Manufacturer
                    </option>
                    <option className="sib-menu__item" value="2">
                      Wholesaler
                    </option>
                    <option className="sib-menu__item" value="3">
                      Retailer
                    </option>
                    <option className="sib-menu__item" value="4">
                      Freight Company
                    </option>
                    <option className="sib-menu__item" value="5">
                      Supplier - Farmer
                    </option>
                    <option className="sib-menu__item" value="6">
                      Supplier - Raw Natural Materials, Non Food
                    </option>
                    <option className="sib-menu__item" value="7">
                      Supplier - Synthetic Materials
                    </option>
                    <option className="sib-menu__item" value="8">
                      Supplier - Other
                    </option>
                    <option className="sib-menu__item" value="9">
                      Courier
                    </option>
                    <option className="sib-menu__item" value="10">
                      Other
                    </option>
                  </select>
                </div>
              </div>
              <label className="entry__error entry__error--primary" htmlFor="">
              </label>
            </div>
          </div>
        </div>
        {/* business emails so no need to request marketing. Send in blue requires it though */}
        <div className={`sib-optin sib-form-block ${classes.formRow} `} data-required="true">
            <div className="sib-optin form__entry entry_block">
              <div className={`form__label-row ${classes.formLabelRow}`}>
                <label className={`entry__label ${classes.formLabel}`} for="ACCEPTS_MARKETING" data-required="*">
                  Confirmation
                </label>
                <div className="entry__choice">
                  <label>
                    <input type="checkbox" className="input_replaced" value="1" id="ACCEPTS_MARKETING" name="ACCEPTS_MARKETING" required />
                    <span className="checkbox checkbox_tick_positive"></span><span ><p>I agree to receive marketing emails from Tecorra Ltd.</p></span> </label>
                </div>
              </div>
              <label className="entry__error entry__error--primary">
              </label>
              <label className="entry__specification">
                You may unsubscribe at any time using the link at the bottom of our emails.
              </label>
            </div>
          </div>
        <div>
          <div className={`sib-captcha sib-form-block ${classes.formRow}`}>
            <div className="form__entry entry_block">
              <div className={`form__label-row ${classes.formLabelRow}`}>
                <div className="g-recaptcha sib-visible-recaptcha" id="sib-captcha" data-sitekey="6LdMeeAaAAAAALNSpidxjqYPVc4DN7kITnfroaFP" data-callback="handleCaptchaResponse"></div>
              </div>
              <label className="entry__error entry__error--primary" htmlFor="">
              </label>
              <label className={`entry__specification ${classes.entrySpecification}`} htmlFor="">
                Form secured by reCAPTCHA
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className={`sib-form-block ${classes.formRow}`}>
            <button className={`sib-form-block__button sib-form-block__button-with-loader ${classes.formSubmitButton}`} form="sib-form" type="submit">
              <svg className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon" viewBox="0 0 512 512">
                <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"
                />
              </svg>
              Register
            </button>
          </div>
        </div>

        <input type="text" name="email_address_check" value={emailAddressCheck} onChange={e => setEmailAddressCheck(e.target.value)} className="input--hidden" />
        <input type="hidden" name="locale" value={locale} onChange={e => setLocale(e.target.value)} />
      </form>
    </div>
  </div>
  <div>
      Have an issue submitting this form? Contact us at hello@qnos.uk.
    </div>
</div>
        </div>
    )
}

export default CompanySignupForm;