import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';

const total = 2000;
const orbSize = 100;
const particleSize = '2px';
const time = '14s';
const baseHue = 180;

const orbHtml = (className) => {
    return Array.from({ length: total })
        .map((_, i) => (<div key={`particle-${i}`} className={className}></div>))
}

function getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const nodeStyles = Array.from({ length: total })
  .map((_, index) => {
    const z = getRandomNumberBetween(0, 360) + 'deg';
    const y = getRandomNumberBetween(0, 360) + 'deg';
    const hue = ((40/total * index) + baseHue)

    return {
            c: {
                position: 'absolute',
                width: particleSize,
                height: particleSize,
                borderRadius: '50%',
                opacity: 0,
                [ `&:nth-child(${index + 1})` ]: {
                    animation: `$orbit${index} ${time} infinite`,
                    animationDelay: `${index * 0.01}` + 's',
                    backgroundColor: `hsla(${hue}, 100%, 50%, 1)`,
                }
            },
            frames: {
                [ `@keyframes orbit${index}`]: {
                    '20%': {
                        opacity: 1,
                    },
                    '30%': {
                        transform: `rotateZ(-${z}) rotateY(${y}) translateX(${orbSize}px) rotateZ(${z})`,
                    },
                    '80%': {
                        transform: `rotateZ(-${z}) rotateY(${y}) translateX(${orbSize}px) rotateZ(${z})`,
                        opacity: 1,
                    },
                    '100%': {
                        transform: `rotateZ(-${z}) rotateY(${y}) translateX(${orbSize * 3}px) rotateZ(${z})`
                    }
                }
            }
        };
  })
  .reduce((prev, curr) => {
      const { c: cPrev, ...cFrames } = prev
      const { c: cCur, frames } = curr

      return {
          c: {
              ...cPrev,
              ...cCur
          },
          ...cFrames,
          ...frames
      }
  }, {});

const useStyles = makeStyles((theme) => ({
    planetAnimationContainer: {
        height: '500px',
        width: '100%',
        position: 'relative',
        maxWidth: '1200px',
        margin: 'auto'
    },
    planetAnimationWrapper: {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 0,
        height: 0, 
        transformStyle: 'preserve-3d',
        perspective: '1000px',
        animation: `spin ${time} infinite linear`
    },
    planetAnimation: {
        position: 'absolute',
        width: particleSize,
        height: particleSize,
        borderRadius: '50%',
        opacity: 0,
    },
    ...nodeStyles,
    '@keyframes spin': {
        '100%:': {
          transform: 'rotateY(360deg) rotateX(360deg)'
        }
    },
}));

const PlanetAnimation = () => {
    const classes = useStyles();
    
    return (
        <div className={classes.planetAnimationContainer}>
            <div className={classes.planetAnimationWrapper}>
            {
                orbHtml( classes.c )
            }
            </div>         
        </div>
    );
}

export default PlanetAnimation;