import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from  '@material-ui/core';

const animationStartDelay = 0.8;
const animationEnd = 1.9;

const animationDelayClasses = Array.from({ length: 100 })
    .map((_, i) => {
        const delay = animationStartDelay + Math.floor(Math.random() * 15) * 0.05;

        return {
            [`lptext${i+1}`]: {
                animationDelay: `${delay}s`
            }  
        }
    })
    .reduce((prev, curr) => { return { ...prev, ...curr }}, {});

const styles = {
    landingPageWrap: {
        width: '100%',
        height: '80vh',
        overflow: 'hidden',
        position: 'relative',
        background: '#131f37',
    },
    landingPageHeaderWrap: {
        position: 'relative',
        zIndex: 11,
    },
    lpImageTextBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // background: '#000',
        borderRadius: '100%',
        width: '50vh',
        height: '50vh',
        zIndex: '-2',
        opacity: '0.9',
        margin: 'auto',
        // background: 'hsl(202deg 58% 19% / 20%)',
    },
    landingPageImageTextWrap: {
        position: 'absolute',
        top: '64px',
        bottom: 0,
        textAlign: 'center',
        left: 0,
        right: 0,
        color: '#fff',
        zIndex: 23,
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        borderBottom: 0,
        

        '&:hover': {
            textDecoration: 'none'
        },

        '&:hover span': {
            custor: 'pointer'
        },

        '& strong span': {
            fontFamily: 'PT Sans',
            fontWeight: 1000,
        },

        '& span': {
            fontFamily: '"Metropolis Light"',
            borderBottom: 0,
        },
    },
    landingPageTextCharacter: {
        opacity: 0,
        willChange: 'opacity,filter,transform',
        webkitAnimation: 'unblur 1s normal forwards',
        animation: 'unblur 1s normal forwards',
        webkitAnimationDelay: '0s',
        animationDelay: '0s',
        webkitBackfaceVisibility: 'hidden',
        backfaceVisibility: 'hidden',
        perspective: 1000,
        transform: 'translateZ(0)',
        fontSize: '4rem',
        fontWeight: 400,
    },
    ...animationDelayClasses,
    lpWord: {
        display: 'inline-block',
    },
    backgroundImage: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        boxSizing: 'content-box',
        opacity: 0.5,
        position: 'absolute',
    },
    lpImageTextBackground: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        transform: 'translate3d(0px, 0px, 0px)',
        willChange: 'transform',
        backfaceVisibility: 'hidden',
    },
    lpImageTextBackgrounds: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
    },
    backgroundColour: {
        backgroundColor: '#000000',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
};

const useStyles = makeStyles((theme) => ( {
    ...styles,
    landingPageHeader: {
        fontSize: '1.2rem',
        [theme.breakpoints.down(1080)]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down(480)]: {
            fontSize: '0.7rem',
        },
        [theme.breakpoints.down(350)]: {
            fontSize: '0.5rem',
        },
    },
    underline: {
        borderBottom: '5px solid rgb(15, 193, 183) !important',
        display: 'block',
        
        '& span': {
            
        }
    }
} ));

const LandingPageImage = ( props ) => {
    const {
        image,
        text,
        isBlank
    } = props;

    const classes = useStyles();

    const renderTextJSX = ( textParts ) => {
        const text = textParts.map(p => p.text).join('')
        const animationLetterDelay = (animationEnd - animationStartDelay) / text.length;
        const result = []

        textParts.forEach((part, partIndex) => {
            const jsx = part.text
                .split(' ')
                .map((word, wordIndex) => {
                    const underlineClass = textParts[partIndex].underline ? classes.underline : '';
                    const { color } = textParts[partIndex]
                    const style = color ? { color } : {}
                    
                    return (
                        <span className={`${classes.lpWord} ${underlineClass}`} key={`lpword-${wordIndex}`} style={style}>
                            {
                                word.split('')
                                    .map((character, index) => {
                                        const animationDelay = animationStartDelay + index * animationLetterDelay;
                                        const lpTextClass = `lptext${index+1}`
                        
                                        const Span = (
                                            <span key={index} className={`${classes.landingPageTextCharacter} ${classes[lpTextClass]}`} style={{ animationDelay }} >{character}</span>
                                        );
                        
                                        if ( part.type === 'basic')
                                            return Span;
                        
                                        return (
                                            <strong key={`${partIndex}_${index}`}>
                                                { Span }
                                            </strong>
                                        );
                                    })
                            }
                            <span className={`${classes.landingPageTextCharacter}`}>&nbsp;</span>
                        </span>
                    )
                });

            result.push(jsx);
        });

        return result;
    }

    if ( isBlank ) {
        return (
            <div className={classes.landingPageWrap}>
                
            </div>
        );
    }

    return (
        <div className={classes.landingPageWrap}>
            
            <div className={classes.landingPageImageTextWrap}>
            <div className={classes.lpImageTextBackgrounds}>
                    <div className={classes.lpImageTextBackground}>
                        <div className={classes.backgroundImage}>
                        {
                            image
                        }
                        </div>
                        <div className={classes.backgroundColour}></div>
                    </div>
                </div>
                <div className={classes.landingPageHeaderWrap}>
                
            <Typography variant="h6" className={classes.landingPageHeader}>
                {
                    renderTextJSX( text )
                }
            </Typography>
            </div>
            </div>
        </div>
    )
}

export default LandingPageImage;