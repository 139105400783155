import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wideVideoWrap: {
        width: '100%',
        position: 'relative',
        zIndex: 1,
        opacity: 0.6,
        height: '80vh',
    },
    wideVideo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
                
    '@keyframes fadeOut': {
        '0%': { background: 'inherit' },
        '50%': { background: 'hsl(202deg 58% 19% / 80%)' },
        '100%': { background: 'inherit' }
    },
          
    fadeOut: {
        animationName: '$fadeOut',
        animationDuration: '6s',
        animationFillMode: 'both',
        animationDelay: '13s',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        background: 'transparent',
    },         
    
}));

const WideVideo = ( props ) => {
    const classes = useStyles();
    const videoRef = React.useRef();
    const [ loading, setLoading ] = React.useState(false);
    const [ loaded, setLoaded ] = React.useState(false);
    const { onVideoEnded, mp4Url, webmUrl, autoPlay } = props;

    const handleOnVideoEnded = () => {
        videoRef.current.currentTime = 0;

        if ( onVideoEnded ) {
            onVideoEnded( videoRef );
        }
    }
    
    const animationDelayStyle = {
        animationDelay: props.animationDelay || '12s'
    };

    const playVideo = () => {
        const vid = videoRef.current;
        const isPlaying = !vid.paused && vid.currentTime > 0 && !vid.ended && vid.readyState > 2;

        if ( autoPlay && loaded ) // && !isPlaying 
            videoRef.current.play();
    }

    const handleVideoLoaded = () => {
        setLoaded(true);

        props.onVideoLoaded && props.onVideoLoaded(webmUrl)
    }

    React.useEffect(() => playVideo(), [ autoPlay, loaded ])

    React.useEffect(() => {
        const vid = videoRef.current;

        if (!vid || loading) return;

        setLoading(true);
        vid.pause();
        vid.load();
    }, [ videoRef ]);

    // React.useEffect(() => {
    //     const vid = videoRef.current;

    //     if (!vid) return;

    //     var r = new XMLHttpRequest();
    //     r.onload = function() {
    //         setVideoSrc( URL.createObjectURL(r.response) );
    //         vid.play();
    //     };

    //     if (vid.canPlayType('video/mp4;codecs="avc1.42E01E, mp4a.40.2"')) {
    //         r.open("GET", mp4Url);
    //     }
    //     else {
    //         r.open("GET", webmUrl);
    //     }

    //     r.responseType = "blob";
    //     r.send();

    // }, [ videoRef ]);

    // return (
    //     <div className={`${classes.wideVideoWrap}`}>
    //         {
    //             videoSrc && (
    //                 <video src={videoSrc} ref={videoRef} onEnded={handleOnVideoEnded} className={`${classes.wideVideo}`} style={animationDelayStyle} poster={props.posterUrl} playsInline muted hight="100%" width="100%" autoPlay></video>
    //             )
    //         }
    //         {
    //             !videoSrc && (
    //                 <div>Loading</div>
    //             )
    //         }
    //     </div>
    // )
    return (
        <div className={`${classes.wideVideoWrap}`}>
            <video ref={videoRef} onCanPlayThrough={handleVideoLoaded} onEnded={handleOnVideoEnded} className={`${classes.wideVideo}`} style={animationDelayStyle} playsInline preload="none" muted hight="100%" width="100%">
                <source data-src={mp4Url} type="video/mp4" src={mp4Url} />
                <source data-src={webmUrl} src={webmUrl} />
            </video>
        </div>
    );
}

export default WideVideo;
