import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ( {
    heading: {
        fontFamily: "'Catamaran', sans-serif",
        fontWeight: 300,
        textTransform: 'capitalize',
        fontSize: '2.25rem',
        color: '#131f37',
        marginBottom: '1rem',
        width: 'auto',
        display: 'block',
        marginRight: 'auto',
        marginTop: 0,

        [theme.breakpoints.down(480)]: {
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
        //borderBottom: '4px solid #485776'
    },
    headingUnderline: {
        
    },
    pageHeader: {
        marginTop: 0,
    }
}));

const WebsiteHeading = ( props ) => {
    const classes = useStyles();

    if ( props.isPageHeader ) {
        return (
            <h3 className={`${classes.heading} ${classes.pageHeader}`}>{ props.text }</h3>
        );

    }

    return (
        <div>
            <h3 className={classes.heading}>{ props.text }</h3>
            <div className={classes.headingUnderline}></div>
        </div>
    )
}

export default WebsiteHeading;
