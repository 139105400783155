import * as React from "react";
import {  makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    lineThrough: {
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        width: 0,
        height: '1px',
        backgroundColor: 'hsla(180.06, 100%, 50%, 1)',
        // animation: `expandLine 2s infinite linear`
    },
}));

const ExpandoLine = () => {
    const classes = useStyles();
    
    return (
        <div className={classes.lineThrough}></div>
    )
}

export default ExpandoLine;
