import React from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem  } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../assets/images/logo-150-qnos.png';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          width: '460px',
          marginLeft: 'auto',
          alignItems: 'flex-end',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
    },
    siteNavlink: {
        color: 'inherit',
        textDecoration: 'none',

        '&:hover span': {
            borderBottom: '2px solid #fff',
            paddingBottom: '0.2rem',
        }
    },
    siteNavbar: {
        transition: 'background-color 500ms linear',

        '&:hover': {
            backgroundColor: 'hsl(202deg 58% 19% / 100%)'
        }
    },
    logo: {
        height: '100%',
        width: 'auto',
        margin: 'auto',
    },
    logoWrap: {
        cursor: 'pointer',
        padding: '0rem 2rem',
        lineHeight: '2rem',
        height: '2rem',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
}))

const PageNavbar = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderMenu = (
        <Drawer anchor='left' open={isMenuOpen} onClose={handleMenuClose}>
      <List>
        <ListItem>
            <a className={classes.siteNavlink} href='/'>
                <Typography variant="h6" className='link'>
                    <span>Home</span>
                </Typography>
            </a>
        </ListItem>
        <ListItem>
            <a className={classes.siteNavlink} href='/solutions/project-phoenix'>
                <Typography variant="h6" className='link'>
                    <span>Solutions</span>
                </Typography>
            </a>
        </ListItem>
        <ListItem>
            <a className={classes.siteNavlink} href='/support-us'>
                <Typography variant="h6" className='link'>
                    <span>Support</span>
                </Typography>
            </a>
          </ListItem>
          <ListItem>
            <a className={classes.siteNavlink} href='/roadmap'>
                <Typography variant="h6" className='link'>
                    <span>Road Map</span>
                </Typography>
            </a>
          </ListItem>
        </List>
    </Drawer>

        
    );

    return (
        <div>
        <AppBar className={`bg--secondary ${classes.siteNavbar}`} position="fixed">
            <Toolbar>
            <IconButton
                edge="start"
                className={`${classes.menuButton} ${classes.sectionMobile}`}
                color="inherit"
                aria-label="open drawer"
                onClick={handleMenuOpen}
            >
                <MenuIcon />
            </IconButton>
            <a className={classes.siteNavlink} href='/'>
                <div className={classes.logoWrap}>
                    <img className={classes.logo} src={Logo} alt="QNOS" />
                </div>
            </a>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
                <a className={classes.siteNavlink} href='/solutions/project-phoenix'>
                    <Typography variant="h6" className='link'>
                        <span>Solutions</span>
                    </Typography>
                </a>
                <a className={classes.siteNavlink} href='/support-us'>
                    <Typography variant="h6" className='link'>
                        <span>Support</span>
                    </Typography>
                </a>
                <a className={classes.siteNavlink} href='/roadmap'>
                    <Typography variant="h6" className='link'>
                        <span>Road Map</span>
                    </Typography>
                </a>
            </div>
            </Toolbar>
        </AppBar>
        {renderMenu}
        </div>
    );
}

export default PageNavbar;